<template>
  <components-card-container>
    <v-card-text class="d-flex justify-center">
      Nessuna funzionalità disponibile.
    </v-card-text>
  </components-card-container>
</template>

<script>
import ComponentsCardContainer from "../components/ComponentsCardContainer";
export default {
  name: "BlankPage",
  components: { ComponentsCardContainer },
};
</script>

<style scoped>
</style>
